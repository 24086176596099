<template>
  <div class="view-register auth mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <h1 class="text-center mb-5"><img src="../assets/logo.png" /></h1>

          <form class="card" @submit.prevent="submit">
            <div class="card-body">
              <p class="text-center text-secondary mb-4">
                Cadastre-se
              </p>

              <form-group
                v-model="name"
                id="name"
                type="text"
                placeholder="Seu nome"
                :errors="errors.name"
              />

              <form-group
                v-model="phone"
                id="phone"
                type="text"
                placeholder="Número de celular"
                :errors="errors.phone"
              />

              <form-group
                v-model="email"
                id="email"
                type="email"
                placeholder="Seu e-mail"
                :errors="errors.email"
              />

              <form-group
                v-model="password"
                id="password"
                type="password"
                placeholder="Escolha uma senha"
                :errors="errors.password"
              />

              <b-form-checkbox
                id="terms"
                v-model="terms"
                name="terms"
                :value="true"
              >
                <small>
                  Declaro que li e concordo com os
                  <router-link :to="{ name: 'terms' }">
                    <strong>
                      Termos de uso
                    </strong>
                  </router-link>
                </small>
              </b-form-checkbox>

              <div class="text-center pt-3">
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="!terms || loading"
                >
                  <loading :show="loading">Cadastrar</loading>
                </button>
              </div>
            </div>
          </form>

          <p class="text-center my-5">
            Já sou cadastrado.
            <router-link :to="{ name: 'login' }">Quero entrar!</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "register",
  data() {
    return {
      terms: false,
      name: "",
      phone: "",
      email: "",
      password: "",
      occupation: null,
      loading: false,
      errors: {
        name: [],
        phone: [],
        email: [],
        password: [],
        occupation: []
      }
    };
  },
  methods: {
    selectOccupation(occupation) {
      this.occupation = occupation;
    },
    submit() {
      this.$message.hide();

      this.loading = true;

      this.$store
        .dispatch("user/register", {
          name: this.name,
          phone: this.phone.replace(/\D/g, ""),
          email: this.email,
          password: this.password,
          occupation: this.occupation
        })
        .then(() => {
          this.$router.replace({ name: "integration" });
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-register {
  width: 100%;
}
</style>
